@import "mixins";
@import "variables";
@import "extends";
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;800&display=swap');

body {
  background-image: url("./../img/cover.jpg");
  background-size: cover;
  background-repeat: repeat-y;
  background-position: center;
  height: 100%;
  overflow-x: hidden !important;
  overflow-y: auto;

}
/* width */
::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 2px;
  -webkit-appearance: none;
}

/* Track */
::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-appearance: none;
}

/* Handle */
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  background: #20572B;
  border-radius: 10px;
  -webkit-appearance: none;
}
.navbar-light .navbar-toggler {
  outline: none;
}

:root {
  --header-height: 300px;
}

.lang p {
  margin: 0;
  color: black;
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
}

.lang .def-lang {
  margin-left: 5px;
  padding: 1px 8px;
  width: 43px;
  color: black;
}

.lang button {
  color: white;
  padding: 1px 8px;
}

.lang .dropdown .dropdown-item {
  background: transparent !important;
  padding: 5px 0;
  margin-left: 130px;
}

.lang .dropdown .dropdown-menu {
  background: transparent !important;
  border: none !important;
}

.lang .dropdown .dropdown-toggle::after {
  display: none !important;
}

.lang .dropdown-toggle:focus {
  box-shadow: none !important;
}

.top {
  background-color: #20572B;
  padding: 5px 45px!important;
  font-family: "Montserrat", sans-serif;
  span {
    color: white;
    font-size: 12px;
    padding: 0 10px;
  }
}

.main {
  width: 100%;
  display: block;
  overflow: hidden;
  height: 100%;
  padding: 0 10rem !important;
  margin: 50px 0;
  &:before {
    content: " ";
    position: absolute;
    width: 300px;
    height: 300px;
    background-image: url("./../img/ellipse.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
  &:after {
    content: " ";
    position: absolute;
    width: 300px;
    height: 300px;
    background-image: url("./../img/ellipse.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: 8rem;
    left: 0;
    z-index: -1;
  }
}
.footer {
  position: relative;
  bottom: 0;
  padding: 0 50px !important;
  font-family: 'Raleway', sans-serif;
  img {
    width: 120px;
    height: 80px;
    object-fit: contain;
    margin: 0 10px;
  }
  p {
    margin: 20px 0;
    font-size: 12px;
    font-weight: 500;
  }
}
.btn-menu{
  color: #353535 !important;
  font-weight: bold !important;
  background: #20572B;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  transition: 0.3s all;
  &:hover{
    background: #20572B;
  }
  &:focus{
    border: none;
    outline: none;
    box-shadow: none;
  }
  &.shadow{
    -webkit-box-shadow: 0px 15px 25px 0px rgba(0,0,0,0.28);
    box-shadow: 0px 15px 25px 0px rgba(0,0,0,0.28);
    &:hover{
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }
}
.header{
  position: fixed;
  font-weight: 600;
  transition: 0.4s ease-in-out;
  top: 0;
  left: 0;
  right: 0;
  padding: 30px 80px;
  z-index: 4;
  &.white{
    nav{
      .nav-menu{
        a {
          color: white;
        }
      }
    }
  }
  nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav-menu{
      a{
        color: black;
        font-size: 16px;
        padding: 0 35px;
        span{
          position: relative;
          width: 100%;
          &:before{
            content: '';
            position: absolute;
            bottom: -8px;
            height: 2px;
            width: 0;
            transition: 0.4s ease-in-out;
            background: black ;
          }
        }
        &.active{
          span{
            &:before{
              width: 35px;
            }
          }
        }
        &:hover{
          span:before{
            width: 100%;
            transition: 0.4s ease-in-out;
          }
        }
      }
    }
    .logo{
      height: 60px;
      width: auto;
      transform: translateY(10px);
      img{
        height: 100%;
      }
    }
    .lang
    {
      p {
        display: flex;
        cursor: pointer ;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 14px;
        margin: 10px;
      }
      .customselect{
        position: relative;
        p {
          color: black;
          text-transform: uppercase;
        }
        svg{
          path{fill: black}
        }
        &.white{
          b{
            background: transparent;
            color: black;

          }
          svg{
            path{fill: black}
          }
        }
        .choose{
          position: absolute;
          top: 20px;
        }
      }
    }

  }
  &.active{
    box-shadow: 0 0 30px 0 rgba(0,0,0,.12);
    background: white;
    padding-top: 20px;
    padding-bottom: 20px;
    nav{
      .logo{
        transform: translateY(2px);
      }
    }
  }
  &.white.active{
    nav{
      .nav-menu{
        a{
          color: black;
        }
      }
    }
  }
}
.line {
  position: relative;
  z-index: 21;

  &::before {
    content: "";
    position: absolute;
    width: 0;
    z-index: -1;
    transition: all .5s ease-in;
  }
}
.line {
  &:hover {
    &::before {
      left: 0;
      bottom: -5px;
      width: 100%;
      margin-top: -5px;
      background: black;
      height: 2px;
    }
  }
}

.error-text {
  font-size: 12px;
  transition: 0.3s ease-in-out;
  margin-bottom: 10px;
}

.error {
  border-color: red !important;
  color: red !important;

  &::-webkit-input-placeholder { /* Edge */
    color: red;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: red;
  }

  &::placeholder {
    color: red !important;
  }
}

.main-text {
  font-family: 'Raleway', sans-serif;
  p {
    color: #20572B;
    font-size: 62px;
    font-weight: 800;
  }
  span {
    font-size: 16px;
    font-weight: 400;
  }
}

.img-box {
  height: 550px;
  width: 100%;
  position: relative;
  background-color: black;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 50%;
  overflow: hidden;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
    }
}

.about-component {
  .about {
    font-family: 'Raleway', sans-serif;
    p {
      color: #20572B;
      font-size: 40px;
      font-weight: 800;
    }
    ul li {
      font-size: 16px;
      font-weight: 400;
      margin: 10px 0;
    }
    span {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .about-img-right {
    height: 600px;
    width: 100%;
    position: relative;
    background-color: black;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 30%;
    overflow: hidden;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
    }
  }
  .about-img-left {
    height: 600px;
    width: 100%;
    position: relative;
    background-color: black;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 30%;
    overflow: hidden;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
    }
  }

}

.product-table {
  th, td {
    background-color: #396E35 !important;
    color: white;
    border: 4px solid white !important;
    //font-weight: normal;
    border-radius: 25px;
    text-align: center;
    //padding: 15px 0 !important;
  }
}

.contact {
  font-family: 'Raleway', sans-serif;
  h4 {
    color: #20572B;
    font-size: 40px;
    font-weight: 800;
    margin: 20px 0;
  }
  p {
    color: #20572B;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
  ul li {
    font-size: 16px;
    font-weight: 400;
    margin: 10px 0;
  }
  span {
    font-size: 16px;
    font-weight: 400;
  }
  .inputs {
    display: flex;
    flex-direction: column;
    input {
      background-color: #F1EDED;
      outline: none;
      border: none;
      height: 40px;
      border-radius: 20px;
    }
    label {
      font-size: 18px;
      font-weight: 400;
      color: #20572B;
    }
  }
  .inputsMessage {
    display: flex;
    flex-direction: column;
    textarea {
      background-color: #F1EDED;
      outline: none;
      border: none;
      border-radius: 20px;
    }
    label {
      font-size: 18px;
      font-weight: 400;
      color: #20572B;
    }
  }
  .map {
    height: 600px;
    width: 100%;
    position: relative;
    background-color: black;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 30%;
    overflow: hidden;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
    }
  }
  button {
    background-color: #4daf42;
    color: white;
    padding: 10px 20px;
    outline: none;
    border: none;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

@media (min-width: 1537px) {
  .main {
    padding: 0 15rem !important;
    margin: 0;
    &:before {
      width: 500px;
      height: 500px;
    }
    &:after {
      width: 500px;
      height: 500px;
    }
  }
  .lang p {
    font-size: 13px;
  }
  .about-component {
    .about {
      p {
        font-size: 45px;
      }
      ul li {
        font-size: 17px;
      }
      span {
        font-size: 17px;
      }
    }
    .about-img-right {
      height: 750px;
    }
    .about-img-left {
      height: 750px;
    }
  }
}
@media (max-width: 1234px){
  .header {
    nav {
      .nav-menu{
        a{
          padding: 0 8px;
        }
      }
    }
  }
}
@media (max-width: 768px){
  .main {
    padding: 0 5rem !important;
    margin: 20px 0;
  }
  .header {
    background-image: url("./../img/cover.jpg");
    nav {
      .btn-menu {
        padding: 10px 15px;
        margin-left: 14px;
        -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.35);
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.35);
      }
      .nav-menu{
        background-image: url("./../img/cover.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        position: fixed;
        width: 400px;
        right: -100%;
        top: 0;
        bottom: 0;
        height: 100%;
        z-index: 220 !important;
        display: flex !important;
        flex-direction: column;
        align-items: flex-end;
        transition: 0.2s ease-in-out;
        justify-content: center;

        &.show{
          right: 0 !important;
        }
        a{
          padding: 0 8px;
          display: block;
          font-size: 18px;
          color: #20572B !important;
          padding-right: 79px;
          margin-bottom: 35px;
          transform: translateY(-100px);
        }
        a{
          font-weight: 400 !important;
          span{
            &:before{
              bottom: unset;
              height: 3px;
              width:0px;
              background:  #20572B;
              top: 10px;
              right: -230px;
            }
          }
          &.active , &:hover{
            color: #20572B !important;
            font-weight: bold !important;
            span:before{
              width: 200px;
              transition: 0.4s ease-in-out;
            }
          }
          &:hover{
            span:before{
              width: 200px;
              transition: 0.4s ease-in-out;
            }
          }
        }
        .mobileElements{
          width: 100%;
          .closeIcon{
            position: absolute;
            left: 31px;
            top: 40px;
          }
          .lists{
            position: relative;
            width: 100%;
            img{
              position: absolute;
              &:nth-child(1){
                left: 0;
                bottom: -257px;
              }
              &:nth-child(2){
                left: 105px;
              }
            }
          }
          .lang{
            position: absolute;
            right: 30px;
            bottom: 45px;
            span{
              margin: 0 10px;
              opacity: 0.4;
              border-radius: 50%;
              height: 50px;
              width: 50px;
              color: #20572B;
              border: 2px solid #20572B;
              display: flex;
              justify-content: center;
              align-items: center;
              &.active{
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
//@media (max-width: 768px){
//  .header{
//    padding: 20px 40px;
//  }
//}

@media (max-width: 576px){
  .main {
    padding: 0 1rem !important;
    margin: 20px 0;
  }
  .header {
    padding: 30px;
  }
  .main-text p {
    font-size: 40px;
  }
  .footer {
    padding: 0 !important;
  }
  .about-component .about p {
    font-size: 35px;
  }
  .product-table {
    overflow-x: scroll;
  }
}

@media (max-width: 485px){
  .header nav .nav-menu {
    width: 320px;
  }
}